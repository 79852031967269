<template>
  <div>
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{
        name: 'Dashboard',
      }"
      >Back Stage Management
    </b-button>

    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{
        name: 'LibraryArea',
        params: { area: 'create', name: 'Library' },
      }"
      >My Library</b-button
    >

    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'Upload' }"
      >Upload</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AccountSettings' }"
      >My Account</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-3 mt-md-5 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'Pricing' }"
      >Features & Pricing</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AppPage' }"
      >Trackd App</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AboutUs' }"
      >About Us</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'MusicRights' }"
      >Your Music Rights</b-button
    >
  </div>
</template>

<script>
export default {
  name: "CreatorNav",
};
</script>

<style></style>
